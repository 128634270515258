/** 
 * These are opionated base styles for the project
 * look at `locale.css` for any specific text rules that apply to specific locales
 */

/**
 * 1. (opinionated) on Webkit browsers, quote marks hang outside the box for better visuals.
 * 2. default to 16px or 1rem, apply to html not body, default to 100% or 1rem: 
 * https://adrianroselli.com/2024/03/the-ultimate-ideal-bestest-base-font-size-that-everyone-is-keeping-a-secret-especially-chet.html#Update01
 */
html {
  hanging-punctuation: first last; /* 1 */
  font-size: 100%; /* 2 */
}

/** 
 * 1. Make sure body is at least 100% high, if this causes issues, you can remove it
 * 2. Cosmos font-smoothing 
 */
body {
  min-height: 100svh; /* 1 */
  color: var(--color-text);
  line-height: 1.5;
  font-family: var(--ff-bull-text);
  -webkit-font-smoothing: antialiased; /* 2 */
}

/**
 * Normalize.css applies some styles which break this web component on Safari,
 * this just un-breaks them again.
 */
cosmos-button {
  -webkit-appearance: unset !important;
}

/** 
 * Cosmos Button currently doesn't have a min width variable, so we make our own 
 */
cosmos-button::part(base) {
  min-width: var(--cosmos-button-min-width, auto);
}

/**
 * 1. Languages like German and Turkish have been problematic with large words
 * causing horizontal overflow in headings because they are too long even when
 * they are on a line by themselves.
 * 
 * https://diesdas.atlassian.net/jira/software/c/projects/RBPCS/boards/114?selectedIssue=RBPCS-238
 * 
 * In situations where this styling causes words to wrap in problematic places
 * we recommend adding a "soft-hyphen" to the long word in Contentful, to 
 * *hint* to the browser where the optimal place to wrap (and add a hyphen)
 * would be.
 *
 * This might need to be broken out into the `locales.css` and only apply to specific languages.
 *
 * 2. Pretty seems to work better on headings than Balance
 */
cosmos-title,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word; /* 1 */
  text-wrap: pretty; /* 2 */
}

/** 
 * 1. Stop orphans/widows
 *
 * We dont have `overflow-wrap: break-word;` as word-breaks can mess up the meanings for 
 * languages with long words, or japanese & korean etc.
 *
 * We might want to add "break-word" globally then remove it for certain locales via `locales.css`
 */
cosmos-text,
p {
  overflow-wrap: break-word;
  text-wrap: pretty; /* 1 */
}

/* Any raw links get an underline and color */
a:not([class]) {
  color: inherit;
  text-decoration: underline;
}

/**
 * Allow smooth scrolling inside the page or other containers with some top padding
 * but only for users who want it.
 *
 * 1. add some magic number space above scroll items
 */
@media (prefers-reduced-motion: no-preference) {
  :has(:target) {
    scroll-behavior: smooth;
    scroll-padding-block-start: calc(var(--navbar-height) + 5ex); /* 1 */
  }
}

/*
 * 1. Create a root stacking context
 * See https://www.joshwcomeau.com/css/custom-css-reset/
 * 
 * 2. Isolate breaks mix-blend-mode used in `stay-tuned`.
 * Apply any theme background colours to the next wrapper.
 */
#__next {
  background-color: inherit; /* 2 */
  isolation: isolate; /* 1 */
}
