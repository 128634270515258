.sustainability-quiz {
  position: relative;
  z-index: 1;
  background-color: var(--color-bg-shade);
}

.sustainability-quiz--hide {
  animation: fadeOutAndHide ease-in 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeOutAndHide {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    z-index: 0;
    height: 0;
  }
}

@keyframes sustainabilityQuizHide {
  0% {
    display: block;
    opacity: 1;
  }

  90% {
    opacity: 0;
  }

  100% {
    display: none;
    z-index: -9000;
  }
}

.sustainability-quiz__content {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-image: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0),
      var(--color-bg-shade)
    ),
    linear-gradient(90deg, var(--color-bg-shade), hsla(0, 0%, 100%, 0));
  padding: 0 24px;
  max-width: 1200px;
  height: 100vh;
  min-height: 550px;
  overflow: hidden;

  @media (width >= 1000px) {
    min-height: 700px;
  }
}

.sustainability-quiz__content--has-high-in-sugar-icon::after {
  position: absolute;
  top: 58px;
  right: 20px;
  background-image: var(--high-in-sugar-icon-url);
  background-size: 100%;
  background-repeat: no-repeat;
  width: 45px;
  height: 58px;
  content: "";
}

.sustainability-quiz__content--has-high-in-sugar-icon-cl::after {
  background-image: var(--high-in-sugar-icon-cl-url);
}

@media (width >= 650px) {
  .sustainability-quiz__content--has-high-in-sugar-icon::after {
    top: 100px;
    width: 55px;
    height: 68px;
  }
}

.sustainability-quiz__copy {
  margin: 0 auto;
  max-width: 420px;
  text-align: center;

  @media (width >= 1000px) {
    max-width: 620px;
  }
}

.sustainability__kicker {
  display: inline-block;
  position: relative;
  z-index: 2;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: var(--font-size-18px);
  line-height: 2;
  text-transform: uppercase;
}

.sustainability-quiz__title {
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-48px);
  line-height: 1;
  font-family: var(--font-family-heading);

  @media (width >= 650px) {
    font-size: var(--font-size-80px);
    line-height: 1;
  }

  @media (width >= 1000px) {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: var(--font-size-96px);
    line-height: calc(88 / 96);
  }
}

.sustainability-quiz__description {
  margin: 24px 0 32px;
  font-size: var(--font-size-16px);
  line-height: calc(24 / 16);

  @media (width >= 1000px) {
    margin: 24px 0 40px;
    font-size: var(--font-size-20px);
    line-height: calc(28 / 20);
  }
}

.sustainability-quiz__video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sustainability-quiz__button {
  transition: box-shadow 200ms ease;
  cursor: pointer;
  outline: none;
  box-shadow: 0 1px 1px 0 transparent;
  border-width: 1px;
  border-style: solid;
  border-color: var(--color-primary);
  border-radius: var(--radius-medium);
  background-color: var(--color-primary);
  padding: 0 20px;
  height: 48px;
  color: var(--color-text-light);
  font-weight: 700;
  font-size: var(--font-size-18px);
  line-height: calc(42 / 18);
  letter-spacing: 0.5px;

  @media (width >= 1000px) {
    font-size: var(--font-size-20px);
  }
}

.sustainability-quiz__button:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  border-color: var(--color-primary-darker);
  background-color: var(--color-primary-darker);
  color: var(--color-text-light);
}
